import { getProfileForm } from '@/services/profileForm';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { StrapiProfileFormInterface } from '~/types/strapiProfileForm.interface';

export const namespaced = true;

export const state = () => ({
  data: {} as StrapiProfileFormInterface,
  status: LoadingStatusEnum.noData as LoadingStatusEnum,
});

export type ProfileFormState = ReturnType<typeof state>;

export interface ProfileFormGettersInterface {
  formData: StrapiProfileFormInterface;
  isLoading: boolean;
  isLoaded: boolean;
}

export const getters: GetterTree<ProfileFormState, any> = {
  formData(state: ProfileFormState): StrapiProfileFormInterface {
    return state.data;
  },
  isLoading(state: ProfileFormState): boolean {
    return state.status === LoadingStatusEnum.loading;
  },
  isLoaded(state: ProfileFormState): boolean {
    return state.status === LoadingStatusEnum.loaded;
  },
};

export const mutations: MutationTree<ProfileFormState> = {
  SAVE_STRAPI_FORM(state: ProfileFormState, data: StrapiProfileFormInterface) {
    state.data = data;
  },
  SET_STATUS(state: ProfileFormState, status: LoadingStatusEnum) {
    state.status = status;
  },
};

export const actions: ActionTree<ProfileFormState, any> = {
  /**
   * function for load profile form from strapi
   *
   * @param context - context object
   * @param options - request options
   **/
  async load(
    ctx: {
      commit: any;
      state: ProfileFormState;
      getters: ProfileFormGettersInterface;
      dispatch: any;
      rootGetters: any;
    },
    lang: string
  ): Promise<void> {
    if (!ctx.getters.isLoaded) {
      ctx.commit('SET_STATUS', LoadingStatusEnum.loading);
    }

    const host = ctx.rootGetters['systemHosts/cms'];
    const [data] = await getProfileForm(host, lang);

    if (data) {
      ctx.commit('SAVE_STRAPI_FORM', data);
      ctx.commit('SET_STATUS', LoadingStatusEnum.loaded);
    } else {
      ctx.commit('SET_STATUS', LoadingStatusEnum.error);
    }
  },
};
