import { loadPlayerInfo } from '@/services/ssPlayer';
import type { GetterTree, ActionTree, MutationTree } from 'vuex';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';

import { SsPlayerResponseInterface } from '~/types/softswiss/ssPlayer.response.interface';

export const namespaced = true;

export const state = () => ({
  playerInfo: {} as SsPlayerResponseInterface,
  status: LoadingStatusEnum.noData as LoadingStatusEnum,
});

export type SsPlayerState = ReturnType<typeof state>;

export interface SsPlayerGettersInterface {
  isPlayerAuthorised: boolean;
}

export const getters: GetterTree<SsPlayerState, any> = {
  isPlayerAuthorised(state: SsPlayerState): boolean {
    return !!state.playerInfo.id;
  },
};

export const mutations: MutationTree<SsPlayerState> = {
  SAVE_PLAYER(state: SsPlayerState, playerInfo: SsPlayerResponseInterface) {
    state.playerInfo = { ...state.playerInfo, ...playerInfo };
  },
  SET_PLAYER_INFO_STATUS(state: SsPlayerState, status: LoadingStatusEnum) {
    state.status = status;
  },
};

export const actions: ActionTree<SsPlayerState, any> = {
  async loadPlayerInfo(ctx: {
    state: any;
    dispatch: any;
    commit: any;
    getters: { shouldLoadPlayerInfo: boolean };
    rootGetters: any;
  }) {
    ctx.commit('SET_PLAYER_INFO_STATUS', LoadingStatusEnum.loading);

    try {
      const ssHost = ctx.rootGetters['systemHosts/ssHost'];
      const playerInfo = await loadPlayerInfo(ssHost, 'en');

      ctx.commit('SAVE_PLAYER', playerInfo);
      ctx.commit('SET_PLAYER_INFO_STATUS', LoadingStatusEnum.loaded);
    } catch (e) {
      ctx.commit('SET_PLAYER_INFO_STATUS', LoadingStatusEnum.error);
    }
  },
};
