import { createStore } from 'vuex';
import * as autochangeData from './autochangeData';
import * as platform from './platform';
import * as promoPage from './promoPage';
import * as country from './country';
import * as currencyMenu from './currencyMenu';
import * as regForm from './regForm';
import * as systemHosts from './systemHosts';
import * as profileForm from './profileForm';
import * as ssCountries from './ssCountries';
import * as ssPlayer from './ssPlayer';
import * as stag from './stag';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    autochangeData,
    platform,
    promoPage,
    country,
    currencyMenu,
    regForm,
    systemHosts,
    profileForm,
    ssCountries,
    ssPlayer,
    stag,
  },
  strict: process.env.NODE_ENV !== 'production',
});
