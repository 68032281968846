import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { SystemHostsStrapiInterface } from '~/types/strapi/systemHosts.strapi.interface';
import { getSystemHosts } from '@/services/systemHosts';

export const namespaced = true;

export const state = () => ({
  hosts: [] as SystemHostsStrapiInterface[],
  status: LoadingStatusEnum.noData as LoadingStatusEnum,
});

export type SystemHostsState = ReturnType<typeof state>;

export interface SystemHostsGetters {
  regional: string;
  geo: string;
  redirect: string;
  gameImages: string;
  currency: string;
  list: SystemHostsStrapiInterface[];
  current: SystemHostsStrapiInterface | null;
  isCurentHostPromo: boolean;
  isCurentHostCasino: boolean;
  ssHost: string;
  isLoaded: boolean;
}

export const getters: GetterTree<SystemHostsState, any> = {
  /**
   * regional domain host
   * @param state of store
   */
  regional(state: SystemHostsState): string {
    const defaultHost = '7bitcasino.com';

    if (window?.location?.host && state.status === LoadingStatusEnum.loaded) {
      return (
        state.hosts.find((h) => h.host === window.location.host)
          ?.regional_visible_domain || defaultHost
      );
    }

    return defaultHost;
  },

  /**
   * geoip host
   * @param state of store
   */
  geo(_state: SystemHostsState, getters: SystemHostsGetters): string {
    const defaultHost = 'country.7bitcasino8.com';

    if (getters.current) {
      return getters.current?.geo_host || defaultHost;
    }

    return defaultHost;
  },

  /**
   * redirect host
   * @param state of store
   */
  redirectHost(_state: SystemHostsState, getters: SystemHostsGetters): string {
    const defaultHost = '7bt.co';

    if (getters.current) {
      return getters.current?.redirect_host || defaultHost;
    }

    return defaultHost;
  },
  /**
   * images host
   * @param state of store
   */
  gameImages(_state: SystemHostsState, getters: SystemHostsGetters): string {
    const defaultHost = '7bitcdn.com';

    if (getters.current) {
      return getters.current?.game_images_host || defaultHost;
    }

    return defaultHost;
  },

  /**
   * CMS host
   * @param state of store
   */
  cms(_state: SystemHostsState, getters: SystemHostsGetters): string {
    const defaultHost = 'dinamic-content.7bitcdn.com';

    if (getters.current) {
      return getters.current?.cms_host || defaultHost;
    }

    return defaultHost;
  },

  /**
   * geoip host
   * @param state of store
   */
  currency(_state: SystemHostsState, getters: SystemHostsGetters): string {
    const defaultHost = 'currencies.7bitcasino8.com';

    if (getters.current) {
      return getters.current?.currency_host || defaultHost;
    }

    return defaultHost;
  },

  /**
   * list of all hosts
   * @param state of store
   */
  list(state: SystemHostsState): SystemHostsStrapiInterface[] {
    return state.hosts;
  },

  /**
   * current host params
   * @param state of store
   */
  current(state: SystemHostsState): SystemHostsStrapiInterface | null {
    return (
      state.hosts.find(
        (h) => h?.host.toLowerCase() === window?.location?.host
      ) || null
    );
  },

  ssHost(_state: SystemHostsState, getters: SystemHostsGetters): string {
    return getters.current?.ss_host;
  },

  isCurentHostPromo(
    _state: SystemHostsState,
    getters: SystemHostsGetters
  ): boolean {
    return getters.current?.host_type === 'promo';
  },

  isCurentHostCasino(
    _state: SystemHostsState,
    getters: SystemHostsGetters
  ): boolean {
    return getters.current?.host_type === 'casino';
  },

  /**
   * status of loading
   * @param state of store
   */
  isLoaded(state: SystemHostsState): boolean {
    return state.status === LoadingStatusEnum.loaded;
  },
};

export const mutations: MutationTree<SystemHostsState> = {
  SAVE_HOSTS(state: SystemHostsState, hosts: SystemHostsStrapiInterface[]) {
    state.hosts = hosts;
  },
  SET_STATUS(state: SystemHostsState, status: LoadingStatusEnum) {
    state.status = status;
  },
};

export const actions: ActionTree<SystemHostsState, any> = {
  /**
   * function for loading hosts list from strapi
   **/
  async load(ctx: {
    commit: any;
    getters: any;
    dispatch: any;
  }): Promise<boolean> {
    if (!ctx.getters.isLoaded) {
      ctx.commit('SET_STATUS', LoadingStatusEnum.loading);
    }

    const host = ctx.getters['cms'];
    const response = await getSystemHosts(host);

    if (response) {
      ctx.commit('SAVE_HOSTS', response);
      ctx.commit('SET_STATUS', LoadingStatusEnum.loaded);
      return true;
    }
    ctx.commit('SET_STATUS', LoadingStatusEnum.loaded);

    return false;
  },
};
