import axios from 'axios';

export async function getCountries(host: string) {
  const response = await axios.get(`https://${host}/api/info/countries`, {
    withCredentials: true,
    headers: {
      Accept: 'application/vnd.softswiss.v1+json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });

  return response.data;
}
