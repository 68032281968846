import { getCurrency } from '@/services/currency';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  CurrencyMenuItemInterface,
  CurrencyMenuItemParsedInterface,
} from '~/types/currencyMenu.interface';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { RequestOptionsInterface } from '~/types/transport/requestOptions.interface';

export const namespaced = true;

export const state = () => ({
  currencies: [] as CurrencyMenuItemParsedInterface[],
  status: LoadingStatusEnum.noData as LoadingStatusEnum,
});

export type CurrencyMenuState = ReturnType<typeof state>;

export interface CurrencyMenuGettersInterface {
  allCurrencys: CurrencyMenuItemParsedInterface[];
  activeCurrencies: CurrencyMenuItemParsedInterface[];
  cryptoCurrencyCodes: string[];
  isLoading: boolean;
  isLoaded: boolean;
}

export const getters: GetterTree<CurrencyMenuState, any> = {
  allCurrencys(state: CurrencyMenuState): CurrencyMenuItemParsedInterface[] {
    return state.currencies;
  },
  activeCurrencies(
    _state: CurrencyMenuState,
    getters: CurrencyMenuGettersInterface
  ): CurrencyMenuItemParsedInterface[] {
    return getters.allCurrencys
      .filter((c) => c.active)
      .sort((a, b) => a.position - b.position);
  },

  cryptoCurrencyCodes(
    _state: CurrencyMenuState,
    getters: CurrencyMenuGettersInterface
  ): string[] {
    return getters.allCurrencys
      .filter((c) => c.crypto)
      .map((c) => c.currency_code);
  },

  isLoading(state: CurrencyMenuState): boolean {
    return state.status === LoadingStatusEnum.loading;
  },

  isLoaded(state: CurrencyMenuState): boolean {
    return state.status === LoadingStatusEnum.loaded;
  },
};

export const mutations: MutationTree<CurrencyMenuState> = {
  SAVE_STRAPI_CURRENCIES(
    state: CurrencyMenuState,
    currencies: CurrencyMenuItemInterface[]
  ) {
    state.currencies = currencies
      .map((c) => {
        let default_for_countries: string[] = [];
        if (c.default_for_countries) {
          default_for_countries = c.default_for_countries
            .split(',')
            .filter((code: string | null) => !!code)
            .map((c) => String(c).toUpperCase());
        }
        let exluded_countries: string[] = [];
        if (c.exluded_countries) {
          exluded_countries = c.exluded_countries
            .split(',')
            .filter((code: string | null) => !!code)
            .map((c) => String(c).toUpperCase());
        }

        return {
          ...c,
          default_for_countries,
          exluded_countries,
        } as CurrencyMenuItemParsedInterface;
      })
      .sort((a, b) => a.position - b.position);
  },
  SET_STATUS(state: CurrencyMenuState, status: LoadingStatusEnum) {
    state.status = status;
  },
};

export const actions: ActionTree<CurrencyMenuState, any> = {
  /**
   * function for load currencies menu from strapi
   *
   * @param context - context object
   * @param options - request options
   **/
  async load(ctx: {
    commit: any;
    state: CurrencyMenuState;
    getters: CurrencyMenuGettersInterface;
    dispatch: any;
    rootGetters: any;
  }): Promise<void> {
    try {
      if (!ctx.getters.isLoaded) {
        ctx.commit('SET_STATUS', LoadingStatusEnum.loading);
      }

      const host = ctx.rootGetters['systemHosts/cms'];
      const data = (await getCurrency(host)) as CurrencyMenuItemInterface[];

      if (data.length) {
        // imgUrlReplacer(response);
        ctx.commit('SAVE_STRAPI_CURRENCIES', data);
        ctx.commit('SET_STATUS', LoadingStatusEnum.loaded);
      } else {
        ctx.commit('SET_STATUS', LoadingStatusEnum.error);
      }
    } catch (e) {
      ctx.commit('SET_STATUS', LoadingStatusEnum.error);
    }
  },

  async isCryptoCurency(
    ctx: {
      commit: any;
      state: CurrencyMenuState;
      getters: CurrencyMenuGettersInterface;
      dispatch: any;
      rootGetters: any;
    },
    currencyCode: string
  ): Promise<boolean> {
    if (!ctx.getters.isLoaded) {
      await ctx.dispatch('load', {});
    }

    return ctx.getters.cryptoCurrencyCodes.includes(currencyCode);
  },
};
