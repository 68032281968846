import { GetterTree, ActionTree, MutationTree } from 'vuex';
import imgUrlReplacer from '@/utils/imgUrlReplacer';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { PromoPageRequestOptions } from '~/types/transport/promoPageRequestOptions.interface';
import { PromoPageInterface } from '~/types/promoPage.interface';
import { getPromoPages } from '@/services/promoPage';

export const namespaced = true;

export const state = () => ({
  page: [] as PromoPageInterface[],
  status: LoadingStatusEnum.noData as LoadingStatusEnum,
});

export type ModuleState = ReturnType<typeof state>;

export const getters: GetterTree<ModuleState, any> = {
  pageData(state: ModuleState): PromoPageInterface[] {
    return state.page;
  },
  isLoading(state: ModuleState): boolean {
    return state.status === LoadingStatusEnum.loading;
  },
  isLoaded(state: ModuleState): boolean {
    return state.status === LoadingStatusEnum.loaded;
  },
};

export const mutations: MutationTree<ModuleState> = {
  SAVE_STRAPI_PAGE(state: ModuleState, page: PromoPageInterface[]) {
    state.page = page;
  },
  SET_STATUS(state: ModuleState, status: LoadingStatusEnum) {
    state.status = status;
  },
};

export const actions: ActionTree<ModuleState, any> = {
  /**
   * function for loading promo page from strapi
   **/
  async load(
    ctx: {
      commit: any;
      getters: any;
      dispatch: any;
      rootGetters: any;
    },
    options: PromoPageRequestOptions
  ): Promise<void> {
    if ((ctx.getters.isLoaded || ctx.getters.isLoading) && !options?.force) {
      return;
    }
    ctx.commit('SET_STATUS', LoadingStatusEnum.loading);

    try {
      const host = ctx.rootGetters['systemHosts/cms'];
      const data = (await getPromoPages(
        host,
        options.path
      )) as PromoPageInterface[];

      if (data.length) {
        imgUrlReplacer(data);
        ctx.commit('SAVE_STRAPI_PAGE', data);
        ctx.commit('SET_STATUS', LoadingStatusEnum.loaded);
      } else {
        ctx.commit('SET_STATUS', LoadingStatusEnum.error);
      }
    } catch (e) {
      ctx.commit('SET_STATUS', LoadingStatusEnum.error);
    }
  },
};
