import { getRegForm } from '@/services/regForm';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { RegFormInterface } from '~/types/regForm.interface';

export const namespaced = true;

export const state = () => ({
  data: {} as RegFormInterface,
  status: LoadingStatusEnum.noData as LoadingStatusEnum,
});

export type ModuleState = ReturnType<typeof state>;

export const getters: GetterTree<ModuleState, any> = {
  formLang(state: ModuleState) {
    return state.data.lang;
  },
  formData(state: ModuleState) {
    return state.data;
  },
  isLoading(state: ModuleState): boolean {
    return state.status === LoadingStatusEnum.loading;
  },
  isLoaded(state: ModuleState): boolean {
    return state.status === LoadingStatusEnum.loaded;
  },
};

export const mutations: MutationTree<ModuleState> = {
  SAVE_STRAPI_FORM(state: ModuleState, data: RegFormInterface) {
    state.data = data;
  },
  SET_STATUS(state: ModuleState, status: LoadingStatusEnum) {
    state.status = status;
  },
};

export const actions: ActionTree<ModuleState, any> = {
  async load(
    ctx: {
      commit: any;
      state: ModuleState;
      getters: any;
      dispatch: any;
      rootGetters: any;
    },
    lang
  ): Promise<void> {
    if (!ctx.getters.isLoaded) {
      ctx.commit('SET_STATUS', LoadingStatusEnum.loading);
    }

    const host = ctx.rootGetters['systemHosts/cms'];
    const data = (await getRegForm(host, lang)) as RegFormInterface;

    if (data.lang) {
      ctx.commit('SAVE_STRAPI_FORM', data);
      ctx.commit('SET_STATUS', LoadingStatusEnum.loaded);
    } else {
      ctx.commit('SET_STATUS', LoadingStatusEnum.error);
    }
  },
};
