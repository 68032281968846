import { ref, readonly } from 'vue';

const isShow = ref(false);

const openModal = function (): void {
  isShow.value = true;
};

const closeModal = function (): void {
  isShow.value = false;
};

export default function usePromoModal() {
  return {
    show: readonly(isShow),
    openModal,
    closeModal,
  };
}
