import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AutochangeStrapiItem } from '~/types/strapi/autochangeItem.strapi.interface';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { getAutochangeData } from '@/services/autochangeData';

export const namespaced = true;

export const state = () => ({
  autochangeItems: [] as AutochangeStrapiItem[],
  status: LoadingStatusEnum.noData,
});

export type AutochangeDataState = ReturnType<typeof state>;

export const getters: GetterTree<AutochangeDataState, any> = {
  autochangeItems(state: AutochangeDataState) {
    return state.autochangeItems;
  },

  isLoaded(state: AutochangeDataState): boolean {
    return state.status === LoadingStatusEnum.loaded;
  },
};

export const mutations: MutationTree<AutochangeDataState> = {
  SET_AUTOCHANGE_PAGE(
    state: AutochangeDataState,
    autochangeData: AutochangeStrapiItem[]
  ) {
    state.autochangeItems = autochangeData;
  },

  SET_STATUS(state: AutochangeDataState, status: LoadingStatusEnum) {
    state.status = status;
  },
};

export const actions: ActionTree<AutochangeDataState, any> = {
  async load(ctx: {
    commit: any;
    state: AutochangeDataState;
    getters: any;
    dispatch: any;
    rootGetters: any;
  }) {
    if (
      ctx.state.status !== LoadingStatusEnum.noData &&
      ctx.state.status !== LoadingStatusEnum.error
    ) {
      return;
    }

    ctx.commit('SET_STATUS', LoadingStatusEnum.loading);

    try {
      const host = ctx.rootGetters['systemHosts/cms'];
      const response = await getAutochangeData(host);

      if (response) {
        ctx.commit('SET_AUTOCHANGE_PAGE', response);
        ctx.commit('SET_STATUS', LoadingStatusEnum.loaded);
      } else {
        ctx.commit('SET_STATUS', LoadingStatusEnum.error);
      }
    } catch (e) {
      ctx.commit('SET_STATUS', LoadingStatusEnum.error);
    }
  },
};
