import { saveStag } from '@/services/saveStag';
import { GetterTree, MutationTree, ActionTree } from 'vuex';

export const namespaced = true;

const stagToPureStag = (stag: string) => {
  const splittedStag = String(stag).split('_');
  if (splittedStag && splittedStag.length) {
    return splittedStag[0];
  }
  return '';
};

export const state = () => ({
  stag: '',
  wasRegisteredByPureStag: '',
});

export type StagState = ReturnType<typeof state>;

export interface StagGetters {
  isLoaded: boolean;
  currentStag: string;
  currentPureStag: string;
  wasRegisteredByPureStag: string;
  chatraExluded: string[];
}

export const getters: GetterTree<StagState, any> = {
  currentStag(state): string {
    if (state.stag && !state.stag.includes('_')) {
      return state.stag + '_noclickid';
    }
    return state.stag;
  },

  currentPureStag(_state: StagState, getters: StagGetters): string {
    return stagToPureStag(getters.currentStag);
  },

  wasRegisteredByPureStag(state: StagState) {
    return state.wasRegisteredByPureStag;
  },
};

export const mutations: MutationTree<StagState> = {
  SET_STAG(state: StagState, stag: string) {
    state.stag = stag;
  },
  SET_WAS_REGISTERED_BY_PURE_STAG(state: StagState, stag: string) {
    state.wasRegisteredByPureStag = stag;
  },
};

/**
 * Method for getting data about bonus codes and
 * related stag's from Strapi.
 *
 * Temporary using mock data instead of Strapi
 */
export const actions: ActionTree<StagState, any> = {
  async checkStag(
    ctx: {
      state: StagState;
      getters: StagGetters;
      commit: any;
      dispatch: any;
      rootGetters: any;
    },
    lang?: string
  ) {
    if (ctx.getters.currentStag) {
      return;
    }

    if (localStorage && localStorage?.stag) {
      ctx.commit('SET_STAG', localStorage.stag);
    } else if (window && window.location) {
      const stagParams = window.location.href.match(/stag=(\d+(_[\d\w]+)?)/);
      if (stagParams && stagParams[1]) {
        ctx.commit('SET_STAG', stagParams[1]);
      }
    }

    if (localStorage) {
      localStorage.stag = ctx.getters.currentStag;
    }

    const ssHost = ctx.rootGetters['systemHosts/ssHost'];

    if (ctx.getters.currentStag) {
      saveStag(ssHost, { stag: ctx.getters.currentStag }, lang);
    }
  },

  /*
  Sometimes we need to know if user had stag in past
  for example to show sign_up modal if user reloads page
  */
  checkPureStagByWhichWasRegistered(ctx: { commit: any }) {
    if (localStorage && localStorage?.stag) {
      ctx.commit(
        'SET_WAS_REGISTERED_BY_PURE_STAG',
        stagToPureStag(localStorage.stag)
      );
    }
    return '';
  },
};
