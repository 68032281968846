import axios from 'axios';

export async function loadPlayerInfo(host: string, lang: string) {
  const response = await axios.get(`https://${host}/api/player`, {
    withCredentials: true,
    headers: {
      Accept: 'application/vnd.softswiss.v1+json',
      'accept-language': lang,
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });

  return response.data;
}
