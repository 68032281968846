import axios from 'axios';

export async function getPromoPages(host: string, path: string) {
  const response = await axios.get(`https://${host}/promo-land-pages`, {
    params: {
      path,
    },
  });

  return response.data;
}
