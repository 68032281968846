import axios from 'axios';

export async function saveStag(host: string, params: any, lang: string) {
  const response = await axios.get(`https://${host}/api/info/locales`, {
    withCredentials: true,
    params,
    headers: {
      Accept: 'application/vnd.softswiss.v1+json',
      'accept-language': lang,
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });

  return response.data;
}
